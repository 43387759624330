import React, {Fragment} from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


function History ({history}) {

    const handleList = () => {
        history.push("/history");
    }

    return (
        <Fragment>
            <Paper elevation={0} style={{marginTop: "20px", marginLeft: "5px", marginRight: "5px", padding: "10px", maxWidth: "800px"}}>
                <Typography variant="h6" component="p">
                    여산송씨(礪山宋氏)의 유래
                </Typography>
                <Typography variant="body1" component="p">
                    시조는 고려 때 유익(惟翊)이며, 고려 충렬왕 때 중찬(中贊)을 지낸 송례(松禮)의 아들 염(琰)·분(玢)의 형제대에서 원윤공파(元尹公派)·밀직공파(密直公派)·소윤공파(少尹公派)·지신공파(知申公派)·정가공파(正嘉公派)의 5파로 갈라졌는데, 앞의 3파는 염의 아들이고 나머지 2파는 분의 아들이다. 여산송씨는 조선시대에 상신 2명(영의정 1명, 좌의정 1명)과 왕비 1명, 그리고 많은 판서급 인물을 배출했는데, 5파 중에서도 특히 지신공파와 정가공파에서 많은 인물이 나왔다.
                    <br/><br/>
                    지신공파에서는 현수(玹壽)를 비롯하여 세림(世琳)·세형(世珩) 형제, 상현(象賢)·상인(象仁) 형제, 성명(成明)·진명(眞明) 형제, 그밖에 인명(寅明)·문재(文載) 등의 명신들이 나왔다. 현수는 단종의 국구(國舅)로서 단종복위운동 사건 이후에도 세조의 아낌을 받았으나 금성대군(錦城大君) 사건에 연루되어 죽음을 당하였다. 세림은 문장·서화에 뛰어났고, 세형은 명종 때 위사공신(衛社功臣)에 오르고, 대사헌, 호조·이조판서를 역임하였다.
                    <br/><br/>
                    상현은 임진왜란의 절신으로 유명하고, 상인은 인조 때 암행어사·부사·관찰사 등을 지냈다. 성명은 예조·이조판서 등 여러 요직을 역임하고 문명이 높았으며, 진명은 호조·이조·예조판서 등을 지냈는데 청렴하기로 유명하였다. 인명은 좌의정에 올라 당쟁의 타파에 공이 컸으며, 문재는 관찰사·대사헌·호조참판 등을 역임하였다.
                    <br/><br/>
                    정가공파에서는 영의정 질(帙), 중종의 부마 인(寅)과 문림(文琳)·언신(言愼) 등이 나왔다. 질은 중종반정(中宗反正)에 정국공신(靖國功臣)이 되고 영의정에 올랐으며, 그의 손자 인은 중종의 부마가 되고 도총관(都摠管)을 지냈으며, 시문과 서예에 능하였다. 문림은 성종 때 좌리공신(佐理功臣)에 오르고 이조판서를 지냈으며, 언신은 병조·이조판서 등을 지냈는데, 광해군 원년 관찰사로 있을 때 처음으로 족보를 만들었다 한다.
                    <br/><br/>
                    원윤공파에서는 익필(翼弼)이 대표적 인물이다. 선조 때의 대학자로서 당대 '8문장(文章)'의 한 사람으로 꼽혔는데 서출(庶出)이라 벼슬에는 오르지 못하였다. 여산송씨의 5파 중에서 원윤공파는 전라남도 고흥(高興), 밀직공파는 경상북도 영덕(盈德), 소윤공파는 전라북도 부안(扶安)·익산(益山), 지신공파는 전라북도 부안, 정가공파는 경기에 밀집해 있다.
                    <br/><br/>
                    여산송씨 [礪山宋氏] (두산백과)
                </Typography>

                <br/><br/>
                <Button color="primary" variant="outlined" onClick={handleList}>list</Button>
            </Paper>

        </Fragment>
    )

}

export default History;

import React, {Fragment} from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

import List from "./List";
import Deokpyo from "./Deokpyo";
import Pilman from "./Pilman";

function Hall({match}) {
    
    return (
        <Fragment>
            <Router>
                <Route exact path={`${match.url}`} component={List} />
                <Route path={`${match.url}/deokpyo`} component={Deokpyo} />
                <Route path={`${match.url}/pilman`} component={Pilman} />
            </Router>
        </Fragment>
    )
}

export default Hall;

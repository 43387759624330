import React, {Fragment} from 'react';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../../css/style.css";


/**
 * Hall Of Fame
 * 1. 송덕표
 * 2. 송필만
 *
 */
function List ({history, match}) {
    
    const handlePage = (e) => {
    
        switch (e.target.id) {
            case "1":
                history.push(`${match.url}/deokpyo`);
                break;
            case "2":
                history.push(`${match.url}/pilman`);
                break;
            default:
                history.push(`${match.url}`);
        }
    }
    return (
        <Fragment>
            <Paper elevation={0} className="paper">
                <Box className="box-list">
                    <Typography color="white" onClick={handlePage} id="1">송덕표(宋德杓)</Typography>
                </Box>
                <br/>
                <Box className="box-list">
                    <Typography color="white" onClick={handlePage} id="2">송필만(宋必滿)</Typography>
                </Box>
            </Paper>
        </Fragment>
    )
}

export default List;

import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import trees from '../images/trees.jpg'

function Main({history}) {
    
    const handleClick = () => {
        history.push("/tree");
    }
    
    const handleHistory = () => {
        history.push("/history");
    }
    
    const handleFame = () => {
        history.push("/hall");
    }

    return (
        <>
            <Card sx={{ maxWidth: 640, marginTop: "20px", marginLeft: "10px", marginRight: "10px" }}>
                <CardActionArea>
                    <CardMedia component="img" image={trees}/>
                    <CardContent>
                        <Typography color="textSecondary" component="p">
                            "A society grows great when old men plant trees whose shade they know they shall never sit in."
                            <br/>
                            "지난 세대가 자신들은 누리지 못할 그늘을 만들어주는 나무를 심을 때 그 사회는 성장한다."
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button color="primary" variant="contained" onClick={handleClick}>tree</Button>
                    <Button color="primary" variant="contained" onClick={handleHistory}>history</Button>
                    <Button color="primary" variant="contained" onClick={handleFame}>Hall of Fame</Button>
                </CardActions>
            </Card>

            <Chip icon={<CheckCircleIcon />} label="Version 0.2.5" sx={{marginLeft: "10px", marginTop: "5px"}}/>
        </>
    )
}

export default Main;



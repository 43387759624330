import React, {Fragment} from 'react';

import Paper from "@mui/material/Paper";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

/**
 * 목차
 1. 명고와 수평종친회
 2. 수평종친회의 형성 과정
 3. 여산송(礪山宋)씨의 유래

 */
function List ({history}) {
    
    const handlePage = (e) => {
        
        switch (e.target.id) {
            case "1":
                history.push("/history/myungo");
                break;
            case "2":
                history.push("/history/estate");
                break;
            case "3":
                history.push("/history/origin");
                break;
            default:
                history.push("/history");
        }
    }
    
    return (
        <Fragment>
            <Paper elevation={0} className="paper">
                <Box className="box-list">
                    <Typography color="white" onClick={handlePage} id="1">1. 명고(鳴皐)와 수평종친회</Typography>
                </Box>
                <br/>
                <Box className="box-list">
                    <Typography color="white" onClick={handlePage} id="2">2. 수평종친회의 형성 과정</Typography>
                </Box>
                <br/>
                <Box className="box-list">
                    <Typography color="white" onClick={handlePage} id="3">3. 여산송씨(礪山宋氏)의 유래</Typography>
                </Box>
            </Paper>
        </Fragment>
    )
}

export default List;

import React from 'react';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {ListItemButton} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountTree from "@mui/icons-material/AccountTree";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import List from "@mui/material/List";
import {withRouter} from "react-router-dom";

function SideMenu({history, flag, toggleDrawer}) {

    const handleTree = () => {
        history.push("/tree");
    }

    const handleSteps = () => {
        history.push("/steps");
    }

    return (
        <Drawer anchor="left" open={flag} onClose={toggleDrawer(false)}>
            <Box sx={{width: "14rem"}} component="nav" onClick={toggleDrawer(false)}>
                <List>
                    <ListItemButton key="0" onClick={handleTree}>
                        <ListItemIcon><AccountTree/></ListItemIcon>
                        <ListItemText>Tree</ListItemText>
                    </ListItemButton>
                    <ListItemButton key="1" onClick={handleSteps}>
                        <ListItemIcon><PeopleIcon/></ListItemIcon>
                        <ListItemText>촌(寸)수 계산기</ListItemText>
                    </ListItemButton>
                </List>
            </Box>
        </Drawer>
    );
}

export default withRouter(SideMenu);

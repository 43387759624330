import React, {Fragment} from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

//import yard1 from '../../images/grave_yard.png';
import yard2 from '../../images/grave_yard_02.png';


function History ({history}) {
    
    const handleList = () => {
        history.push("/history");
    }
    
    return (
        <Fragment>
            <Paper elevation={0} style={{marginTop: "20px", marginLeft: "5px", marginRight: "5px", padding: "10px", maxWidth: "800px"}}>
                <Typography variant="h6" component="p">
                    종중의 유래
                </Typography>
                <Typography variant="body1" component="p">
                    원래 "종중(宗中)"은 공동 선조를 가지고 특정 지역에 모여사는 친족을 중심으로 대(代)를 이어가는 계보를 형성하면서 유래되었다. 옛날에는
                    거의 농경사회였고 따라서 자신이 태어난 곳을 크게 벗어나지 않았으므로 조상의 분묘수호, 봉제사, 친목도모는 어떻게 보면 당연하고
                    자연스러운 일상이었다.
                    <br/><br/>
                    그래서 고유한 의미의 종중은 자연발생적인 "종족집단체"라고 할 수 있다. 그러나 산업사회로 접어들고 도시가 발달하면서, 또 인터넷 정보사회가 되면서
                    친족 관계가 축소되기 시작했다. 더구나 현대에는 다양한 교류가 가능해지면서 친족 중심의 농경사회를 배경으로 하는
                    종중은 그 기능적인 목적을 찾기 어렵게 되었다.
                    <br/><br/>
                </Typography>
                <Typography variant="h6" component="p">
                    수평종친회의 결성
                </Typography>
                <Typography variant="body1" component="p">
                    수평종친회는 지역적으로 보면 경기도 이천시 장호원읍 풍계리 "수평말"이지만 사실 그 지역에 사는
                    회원은 거의 없다. 뿐만 아니라 장호원을 중심으로 자연발생적인 종중이 있었다는 근거도 없다. 다만 명고(鳴皐) 어르신이 태어나신 곳이고
                    또 당신께서 마련하신 선산이 있는 곳, 그리고 "풍계리 782번지"에 명고 기념관(상효재)가 있다는 점에서 의미가 남다른 곳이다.
                    <br/><br/>
                    앞서 기술한 바와 같은 고유한 의미의 종중은 자연발생적이고 또 설령 종중이 있었다고 해도 지금 수평종친회처럼 정관을 갖추고
                    정기 총회와 같은 의사결정기구가 있었다고 보기 어렵다. 각자 자신의 조상을 모시는 가까운 친족들의 모임은 있었던 것으로 짐작된다.
                    <br/><br/>
                    1994년 겨울, 송종헌(초대 회장), 송선영, 송선문, 송선우, 송문달, 송현달 등이 모여 덕표후손(규운, 규형, 규문)으로 이루어진 종중을 결성하게 된다. 이 종중은 "유사" 종중으로 고유한
                    의미의 종중과 구별된다. 회원 자격을 제한하는 등의 정관을 만들고 회장을 선출하고 회계, 감사 등을 담당하는 임원진을 구성함으로써 법적인 권리를 행사하고 분묘수호와 관련된 분쟁에
                    보다 효과적으로 대응할 수 있는 체제를 갖추기 시작했다. 유사 종중은 종중 소유의 자산을 비롯하여 회원들을 일정한 규약과 절차에 의해 관리하는
                    사적인 자치단체의 성격을 가지고 있다.
                    <br/><br/>
                </Typography>
                <Typography variant="h6" component="p">
                    골프장 임대 사업
                </Typography>
                <Typography variant="body1" component="p">
                    종중(또는 종친회)는 소셜 미디어나 각종 커뮤니티와 비교할 때 현대 사회에서 그렇게 의미있는 모임은 아니다. 그러나 종중이 자산을 소유하고 있다면, 다시 말해서 종친회원들의 이익과 밀접한 관계가 형성되는 경우에는
                    전혀 다른 양상을 보이게 된다.
                    <br/><br/>
                    2007년 즈음에 골프 리조트 사업자인 블랙스톤 리조트의 대리인이 명고 기념관을 찾아왔다. 당시 명고 기념관을 관리하던 송현달 회원에게 선산에 골프 리조트를 건설하겠다는 의사를
                    타진해 온 것이다. 이후 수평종친회는 여러 번의 총회와 토론 끝에 선산을 30년 동안 임대해 주기로 결정하고 2007년에 블랙스톤 리조트와 계약을 맺게 된다. 보증금과 매월 지급되는 임대 수익 그리고
                    선산에 흩어진 묘들을 이장하여 묘원을 조성하고 관리해주는 조건을 포함시켰다. 수평종친회 회원들 입장에서는 매우 고무적인 일이었고 많은 회원들이
                    종친회의 일에 관심을 가지는 계기가 되었다.
                </Typography>
                <img src={yard2} width="100%" style={{marginTop: "10px"}}/>
                <br/><br/>
                <Button color="primary" variant="outlined" onClick={handleList}>list</Button>
            </Paper>
            
        </Fragment>
    )
    
}

export default History;

import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Main, Treeview, Member} from "./layouts";
import History from "./layouts/history";
import Hall from "./layouts/hall";
import Steps from "./layouts/steps";
import SideMenu from "./layouts/sidemenu";

export default function App() {

    const [flag, setFlag] = useState(false);

    const handleHome = () => {
        window.location.href = "/";
    }

    const openLeftMenu = () => {
        setFlag(true);
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setFlag(open);
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar sx={{padding: "5px 0px 5px 10px"}}>
                    <Box sx={{flexGrow: 1}}>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={openLeftMenu}>
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        <Typography variant="body1">
                            여산송씨정가공파 덕표후손<br/>(규운 규형 규문) 수평 종친회
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton color="inherit" onClick={handleHome}>
                            <HomeIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Router>
                <Switch>
                    <Route exact path="/" component={Main} />
                    <Route exact path="/tree" component={Treeview} />
                    <Route path="/tree/member/:id" component={Member} />
                    <Route exact path="/history" component={History} />
                    <Route exact path="/hall" component={Hall} />
                    <Route exact path="/steps" component={Steps} />
                </Switch>
                <SideMenu flag={flag} toggleDrawer={toggleDrawer}/>
            </Router>
        </>
    );
}

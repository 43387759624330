import React, {Fragment} from 'react';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import pilman from "../../images/pilman.png";

function Pilman ({history}) {
    

    const handleList = () => {
        history.push("/hall");
    }
    
    return (
        <Fragment>
            <Paper elevation={0} style={{marginTop: "20px", marginLeft: "5px", marginRight: "5px", padding: "10px", maxWidth: "800px"}}>
                <Typography variant="h6" component="p">
                    송필만(宋必滿, 1887~1978)
                </Typography>
                <Typography variant="body1" component="p">
                    송필만은 1887년 경기도 이천군 장호원에서 태어났다. 3세 되던 해에 어머니가 돌아가시고 백부 송철구의 보살핌을 받으며 자랐다.
                    1909년 기호(畿湖)학교(지금의 중앙고등학교)에서 수학하다가 일본으로 건너가서 주오대학(中央大學) 법과를 1919년에 졸업하고
                    귀국하여 연희전문학교(지금의 연세대학교)에서 교수로 재직했다.
                    <br/><br/>
                    미국 유학 준비를 위해 일본에 머물던 중 1923년 관동대지진으로 인하여 목숨을 잃을 뻔했으나 미국 선교사의 도움으로 하와이로
                    밀항했다. 이 시기에 하와이에 있던 이승만(李承晩)을 만나게 되면서 대한인동지회 총무로 기관지인 "태평양잡지(太平洋雜誌)"의 발간을
                    맡아 일본의 관동대지진 학살을 대외적으로 알리는데 노력했다.
                    <br/><br/>
                    미국으로 유학하여 1927년 서남대학(Southwest College)을 졸업한 후 아메리칸 대학교(American University)에서
                    "The Korean Law of Domestic Relations"라는 논문으로 석사학위를 받았다.
                    <br/><br/>
                    1931년 6월 하와이로 건너가 "태평양주보(太平洋週報)"를 발행하면서 이승만 지지 세력 규합을 위해 활동했다.
                    1932년 중반까지 미국에서 활동하다가 귀국했으나 일제강점기의 탄압과 감시로 인해 해방이 될 때까지 충청북도 진천에서 농사를 하며 지냈다.
                    <br/><br/>
                    반민특위 조사위원과 부위원장으로 활동하였고, 1948년 5월 10일 실시한 제헌국회의원 선거에서 충청북도 진천군 한국민주당 후보로 출마해 당선되었으며
                    국회 법제사법위원회에 소속되어 대한민국 헌법 제정에 참여했다. 그러나 자유당의 독재가 이어지자 이에 반대하여 이승만과 멀어지게 되고 결국 재야의 정치인들과 함께 활동하게 되었다.
                    1960년 4·19 혁명 후 8월에 실시된 참의원 선거에서 충청북도 민주당 후보로 당선, 국회법 심의위원으로 위촉되었다.
                    <br/><br/>
                    1961년 5·16 군사 쿠테타가 일어나고 1967년 제 6대 대통령 선거에서 윤보선(尹潽善) 후보가 패하자 모든 정치활동에서 은퇴하고 여생을
                    서울 마포구 창전동에서 보냈다. 1978년 7월 23일 92세를 일기로 생을 마감했다. 국민훈장 무궁화장을 수여받았다. 유택(幽宅)은 수평묘원에 이장되었다.
                    <br/><br/>
                </Typography>
                <img src={pilman} width="100%" style={{marginTop: "10px"}}/>
                <br/><br/>
                <Button color="primary" variant="outlined" onClick={handleList}>list</Button>
            </Paper>
        
        </Fragment>
    )
    
}

export default Pilman;

import React, {Fragment, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import CollapsableIcon from '@mui/icons-material/RemoveCircleOutline';
import ExpandableIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/lab/Alert';

import Spinning from './Spinning';
import {isEmpty} from 'lodash';
import {
    buildTreeData,
    findMember,
    getCount,
    getPartyCount,
    getAliveCount,
    getTreeData
} from '../utils/data';

import CountUp from 'react-countup';

function Treeview({history}) {

    const [expanded, setExpanded] = React.useState(["3"]);
    const [selected, setSelected] = React.useState([]);
    const [info, setInfo] = React.useState("");
    
    const [val, setVal] = useState('');
    const [result, setResult] = useState({});
    const [totalCount, setTotalCount] = useState(0);
    const [partyCount, setPartyCount] = useState([0,0,0]);
    const [aliveCount, setAliveCount] = useState(0);

    
    useEffect(() => {
        //console.log("GET DATA!");
        //buildTreeData().then((data) => setResult(data));
        getTreeData().then((data) => {
            buildTreeData(data).then((data) => setResult(data));
        });
        getCount().then((data) => setTotalCount(data));
        getPartyCount().then((data) => setPartyCount(data));
        getAliveCount().then((data) => setAliveCount(data))
    }, []);
    
    const handleSelect = (e, nodeIds) => {
        //TODO 상세조회
        //getDetail(nodeId).then((data) => console.log(data));
        setSelected(nodeIds);
    }
    
    const handleToggle = (e, nodeId) => {
        setExpanded(nodeId);
    }
    
    const handleChange = (e) => {
        e.preventDefault();
        const v = e.target.value.toString().replace(/\s+/g, '');
        setVal(v);
    }
    
    const handleFind = () => {
        if (!isEmpty(val)) {
            //console.log(val);
            findMember(val).then(data => {
                if (data.selfNodes.length > 0) {
                    setExpanded(data.upperNodes);
                    setSelected(data.selfNodes);
                } else {
                    setExpanded(["3"]);
                }
                setInfo(`${data.selfNodes.length}명 조회`);
                
            });
        }
    }
    
    const handleReset = () => {
        setExpanded(["3"]);
        setVal("");
        setInfo("");

    }
    
    const handleMore = () => {
        console.log(selected);
        if (Array.isArray(selected) && selected.length > 1) {
            setInfo("MORE를 누르려면 한 명을 선택하세요");
        } else {
            history.push(`/tree/member/${selected}`);
        }
    }
    
    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    )
    
    
    return (
        <Fragment>
            <Paper sx={{margin: "10px 10px 10px 10px", padding: "5px 5px 10px 10px"}}>
                <TextField id="standard-required" label="Search" onChange={handleChange} placeholder="이름" value={val}/>
                    {isEmpty(info)?
                        <div style={{marginTop: "10px"}}>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button onClick={handleFind}>find</Button>
                                <Button onClick={handleReset}>reset</Button>
                            </ButtonGroup>
                        </div>
                        :
                        <div style={{display:"flex", flexDirection:"column", height:"85px", justifyContent:"space-between", marginTop:"10px"}}>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button onClick={handleFind}>find</Button>
                                <Button onClick={handleMore}>More</Button>
                                <Button onClick={handleReset}>reset</Button>
                            </ButtonGroup>
                            <Alert severity="info" style={{paddingTop: "2px", paddingBottom: "2px"}}>{info}</Alert>
                        </div>
                    }
            </Paper>
            <Paper sx={{margin: "10px 10px 10px 10px", padding: "5px 5px 10px 10px"}}>
                {isEmpty(result)?
                    <Spinning type="Grid" xy={50} />
                    :
                    <TreeView
                        defaultCollapseIcon={<CollapsableIcon />}
                        defaultExpandIcon={<ExpandableIcon />}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                        selected={selected}
                        expanded={expanded}
                        multiSelect={false}>
                        {renderTree(result)}
                    </TreeView>
                }
            </Paper>
            <Paper sx={{margin: "10px 10px 10px 10px", padding: "5px 5px 10px 10px"}}>
                <b>전체 회원수 <CountUp start={0} end={totalCount} decimals={0} /></b>
                <Box width="92%">
                    <Box width="20%" bgcolor="lightblue" p={1} my={0.5}>
                        규운(<CountUp start={0} end={partyCount[0]} decimals={0} />)
                    </Box>
                    <Box width="64%" bgcolor="lightblue" p={1} my={0.5}>
                        규형(<CountUp start={0} end={partyCount[1]} decimals={0} />)
                    </Box>
                    <Box width="34%" bgcolor="lightblue" p={1} my={0.5}>
                        규문(<CountUp start={0} end={partyCount[2]} decimals={0} />)
                    </Box>
                </Box>
            </Paper>
            <Paper sx={{margin: "10px 10px 10px 10px", padding: "5px 5px 10px 10px"}}>
                <b>현재(생존) 회원수 <CountUp start={0} end={aliveCount} decimals={0} /></b>
            </Paper>
        </Fragment>
    )
}

export default Treeview;



import React, {Fragment, useEffect, useState} from 'react';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {getDetail, findBefore, findAfter, buildTreeData} from '../../utils/data';
import CollapsableIcon from "@mui/icons-material/RemoveCircleOutline";
import ExpandableIcon from "@mui/icons-material/AddCircleOutline";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import {isEmpty} from 'lodash';
import Spinning from "../Spinning";

//
// const useStyles = makeStyles((theme) => ({
//     root: {
//         maxWidth: 460,
//         backgroundColor: "lightgreen",
//     },
//     table: {
//         maxWidth: 460,
//         backgroundColor: "lightgoldenrodyellow",
//     },
//     cellFont: {
//         fontFamily: "맑은 고딕",
//         fontSize: "medium",
//     },
//     treeview : {
//         flexGrow: 1,
//         maxWidth: 460,
//         backgroundColor: "lightgoldenrodyellow",
//         padding: 5
//     }
// }));


function Member ({history, match}) {
    

    const [expanded, setExpanded] = useState([]);
    const [info, setInfo] = useState([]);
    const [rows, setRows] = useState([]);
    const [result, setResult] = useState({});
    
    
    useEffect(() => {
        //console.log(match.params.id);
        const nodeId = match.params.id;
        if (nodeId !== undefined && !isNaN(nodeId)) {
            
            getDetail(nodeId).then((data) => {
                setInfo(data.result.rows[0]);
            });
    
            findBefore(nodeId).then((data) => {
                setRows(data.result.rows);
            });
            
            findAfter(nodeId).then((data) => {
                buildTreeData(data).then((data) => {
                    setResult(data);
                    setExpanded([nodeId]);
                });
            });
        }
    }, [])
    
    
    const handleList = () => {
        history.push("/tree");
    }
    
    const handleToggle = (e, nodeId) => {
        setExpanded(nodeId);
    }
    
    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    )
    
    
    return (
        <Fragment>
            <Paper elevation={1} sx={{margin: "10px 10px 10px 10px", padding: "5px 5px 10px 10px"}}>
                <List sx={{backgroundColor: "lightgreen"}}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <AccountCircle />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Badge badgeContent={match.params.id} color="primary" max={1000}>
                                <Typography variant="h6" component="p">
                                    {info.length>0?`송${info[1]}(宋${info[2]})`:"OOO"}
                                </Typography>
                            </Badge>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            {info.length>0?`${info[3]}`:"-"} 世孫
                            {info.length>0 && info[6] !== '-'?`, ${info[6]}生`:""}
                            {info.length>0 && info[7] !== '-'?` ${info[7]}卒`:""}
                            {/*<br/>*/}
                            {/*父 송현달 / 祖父 송필만*/}
                        </ListItemText>
                    </ListItem>
                </List>
                <Typography component="p" style={{marginTop: "15px"}}>
                    👨‍👩‍👦<br/>
                    [先代]
                </Typography>
                <Table size="small" sx={{backgroundColor: "lightgoldenrodyellow"}}>
                    <TableBody>
                        {
                            rows.length>0?
                                rows.map((arr) =>
                                    <TableRow key={arr[0]}>
                                        <TableCell align="center" sx={{fontSize: "medium"}}>{arr[1]}</TableCell>
                                        <TableCell sx={{fontSize: "medium"}}>{`${parseInt(arr[2])-1} 代祖`}</TableCell>
                                    </TableRow>
                                )
                                :
                                <TableRow key={0}>
                                    <TableCell>-</TableCell>
                                    <TableCell>-</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
                <Typography component="p" style={{marginTop: "10px"}}>
                    [後代]
                </Typography>
                {
                    isEmpty(result)?
                    <Spinning type="Grid" xy={20} />
                    :
                    <TreeView
                        sx={{backgroundColor: "lightgoldenrodyellow"}}
                        defaultCollapseIcon={<CollapsableIcon />}
                        defaultExpandIcon={<ExpandableIcon />}
                        onNodeToggle={handleToggle}
                        expanded={expanded}
                        multiSelect={false}>
                        {renderTree(result)}
                    </TreeView>
                }
                
                <Button color="primary" variant="outlined" onClick={handleList} style={{marginTop: "15px"}}>back</Button>
            </Paper>
        
        </Fragment>
    )
    
}

export default Member;

import React, {Fragment} from 'react';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";


function Deokpyo ({history}) {

    const handleList = () => {
        history.push("/hall");
    }
    
    return (
        <Fragment>
            <Paper elevation={0} style={{marginTop: "20px", marginLeft: "5px", marginRight: "5px", padding: "10px", maxWidth: "800px"}}>
                <Typography variant="h6" component="p">
                    송덕표(宋德杓, 1734~1762)
                </Typography>
                <Typography variant="body1" component="p">
                    덕표공(公)은 여산송씨(礪山 宋氏) 정가공파(正嘉公派) 22대손이다.
                    <br/>
                    아쉽게도 덕표공에 대한 자세한 사료는 찾아보기 어렵다. 유일하게 남아있는 기록은 족보에 남아 있는 것 뿐인데 그마저도 차이가 있다. 전국 여산송씨 종파들은 각각 자신들의 중시조를 기준으로 파보(派譜)를 출판하기도 하고, 모두 모아서 대동보(大同譜)로 간행하기도 한다. 대동보인 정유보(2017년)에는 덕표공에 대해 다음과 같이 기록되어 있다.
                    <br/><br/>
                    英祖之亥別試武科 公智謀過人有武是略年趙相載浩薦公于英廟持除宣傳官後除訓鍊院主薄俄 爾迢擢拜世子翊衛司左翊衛陞資宣略將軍英庙三十七年辛巳遇金尙魯洪麟漢等慘害 公之年二十八歲公厚被莊獻世子信愛故金洪兩小先除公三十八年壬午誣陷儲君 ○配延安李氏父亨純墓廣州山谷 ○繼配潘南朴氏公被禍之夜與子五人及心腹奴屬二人避禍于廣州山谷其翌夜與侍婢金盆同徃京城西大門外新自歛禭而歸葬于山谷公墓仍此地耳朴氏長湖院邑山四七番地卯坐合祔三男
                    <br/><br/>
                    대략적인 내용은 다음과 같다.<br/>
                    <i>"영조 을해년 무과별시(別試武科)를 통해 벼슬길에 올랐으며 정승 조재호가 영조에게 천거하여 선전관에 제수, 후에는 훈련원 교관으로 승진, 이어 세자익위사 좌익위, 선략장군으로 품급이 올랐다. 그러나 영조 37년 신사년에 김상로 홍인한 등의 참화(慘害)를 만났으니, 공의 나이 28세였다. 공이 장헌세자의 신망을 받았기에 모함을 받아 죽었다. ○부인 연안 이씨는 아버지가 형순이고, 묘는 광주(경기도 광주) 산골짜기에 있다 ○계비(繼配) 반남박씨는 공이 화를 당하던 밤에, 다섯아들과 심복과 더불어, 광주산곡(廣州山谷)으로 화를 피했으며 그 다음날 밤에 시종 김분과 더불어, 경성 서대문밖으로 같이 가서 시신을 수습하고 산곡에 장사지내니, 공의 묘가 그대로 이 땅에 있을 따름이다. 박씨는 장호원읍 산47번지 묘좌에 합장되었고, 세 아들을 두었다."</i>
                    <br/><br/>
                    덕표공의 비극적인 죽음이 역사적인 사실과 혼재되어 있어서 명확한 것처럼 보이지만 사실 객관적인 사료가 남아 있는 것은 아니다. 반남박씨(潘南朴氏)의 묘가 장호원읍 산47번지에 있다고 기술된 점을 볼 때 적어도 그 당시에 기록된 것은 아니고 행정지명과 지번의 체계가 잡힌 이후라고 추측된다. 덕표공 묘를 "광주산곡"으로부터 언제 장호원으로 이장했는지는 알 수 없다.
                    <br/><br/>
                    수평종친회의 명고 어르신이 최초 작성했다고 알려져 있는(1920년 중반으로 추측) "명고보(鳴皐譜)"라는 필사본에는 전반부와 함께 다음 부분이 추가되어 있다.
                    <br/><br/>
                    爾迢擢拜世子翊衛司左翊衛陞資宣略將軍英庙三十七年辛巳遇金尙魯洪麟漢等慘害,公之年二十八歲,公厚被莊獻世子信愛,故金洪兩小先除公,三十八年壬午誣陷諸君 配延安李氏亨純之女墓失傳系配潘南朴氏之女公被禍之夜夫人朴氏與子五人及心腹奴屬二人被禍于廣州山谷其翌夜夫人朴氏與侍婢金盆同往京城西門外親自歛禭而㱕葬于山谷 公墓仍存此地耳朴氏墓在陰竹東面赤界里藪村三峯山下益久實卯㘴之原<u>追而言故記曰正祖丁酉年英祖三十八年間慈憲世子補座壬午士禍之時世子補位忠信自發刺缺烈死後讚曰戊戌年王而歎曰淸貧忠信失矣死後復權 其後孫慰撫贈賜牌之地正租戊戌年王勅命下賜牌之地廣州牧陰竹縣連大山吉地安葬正租讚曰我朝鮮國民高枕閑臥之事宋德杓外八人之功德也其後孫下賜牌之地境地東方(艮坐)宮谷之地西方(亥坐)柳累之地南方(午坐)鳳岩道弄谷之地北方(子坐)連大山地礪山宋氏以前(天地譜)追贈收錄之史事實也</u>
                    <br/><br/>
                    <i>"덧붙여 말하자면, 정조 정유년, 영조 38년 장헌세자를 보좌하고, 임오사화에 세자를 지키며 충성심으로 자결하여 장렬히 죽었고, 정조가 무술년에 탄식하며 말하길 '청빈한 충신을 잃었도다' 하고, 사후복권과 그 후손들을 위로하여 사패지를 내려 광주목 음죽현 연대산의 길한땅에 안장하고 정조가 그를 기리며 말하길 '우리 조선 국민들이 편히 누울 수 있는 것은 송덕표 외 8인의 공덕이다' 하였다. 그 후손에게 내린 사패지가, 땅이 동쪽방향(간좌)으로는 궁곡의 땅이요, 서쪽방향(해좌)으로는 유루의 땅이오, 남쪽방향(오좌)으로는 봉암도 농곡의 땅이요, 북쪽방향(자좌)으로는 연대산이다 하였으니, 여산 송씨의 이전(천지보)에 추증에 대한 기록이 있다."</i>
                    <br/><br/>
                    이 글에 의하면 정조가 자신의 父 장헌세자를 끝까지 보위한 덕표공을 높이 칭찬하여 그 보답으로 사패지(賜牌之地)를 하사했다는 내용이 있다. "연대산"과 "봉암"이라는 지명으로 미루어 현재의 장호원 일대로 추측할 수 있지만 그 지역에 덕표 후손들이 사패지를 받아서 대대로 살았다는 역사적인 근거는 찾기 힘들다. 연대산에 안장했다는 덕표공의 묘 역시 그곳에 있었다는 사실을 입증하기는 불가능하다. 더구나 이러한 추증이 기록되어 있다는 "천지보"가 구체적으로 어떤 것을 가리키는 것인지 알 수 없다. 또 전답(田畓)이 아닌 산을 사패지로 하사했다는 것은 덕표공의 공덕을 기린다는 맥락에서 볼 때 이해할 수 없는 부분이다.
                    <br/><br/>
                    일제 강점기에 작성된 임야원도를 보면 당시 그 지역은 청주 한씨(淸州 韓氏)의 소유로 된 땅이 많았다. 물론 다른 성씨 소유의 땅들도 섞여 있었고 규형계 27세손인 필대(必大), 필영(必永), 필만(必滿) 소유의 땅도 있었다. 필만 어르신이 일찍 모친을 잃고 큰아버지댁에서 자랐다는 사실을 상기해보면 이들은 모두 원구(源龜) 할아버지의 후손들로 장호원 풍계리에 살았다는 것을 유추해볼 수는 있다. 유일하게 필만 어르신 소유의 임야 산47번지가 1968년 등기되었고 이후 수평종친회가 그 땅의 일부를 기증받아 지금까지 관리하고 있다.
                    <br/><br/>
                    명고보는 한 사람이 작성을 시작했지만 후에는 다수에 의해 첨삭된 것으로 보인다. 엄밀히 말하면 "작자미상"의 기록이다. 특히 계비(系配) 반남박씨(潘南朴氏)에 관해 기술한 후반부는 필체와 사용된 필기구가 이전에 작성된 것과 차이가 있는 것으로 볼 때 나중에 추가된 것으로 판단된다. 비교적 최근(2017년) 간행된 정유보에 적힌 내용과 비교하면 전반부는 대동소이하지만 후반부는 어느 곳에서도 찾아볼 수 없다는 것도 특이한 점이다.
                    <br/><br/>
                    십수년에 걸쳐 수보(修譜)되면서 내용이 소실되거나 추가되었을 것이라는 점은 짐작할 수 있지만 적지 않은 내용이 공식적으로 간행된 대동보에 없다는 것은 이상한 일이다. 물론 앞서 말한 것처럼 덕표공에 대한 객관적인 사료를 찾기 어렵기 때문에 전체적인 사실관계 그 자체도 다양한 해석이 가능하다. 그러나 역사는 대안적인 진실이 아니라 실체적인 진실이다.

                </Typography>
                <br/><br/>
                <Button color="primary" variant="outlined" onClick={handleList}>list</Button>
            </Paper>
        
        </Fragment>
    )
    
}

export default Deokpyo;

import React, {Fragment, useEffect, useRef, useState} from 'react';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import TreeView from "@mui/lab/TreeView";
import CollapsableIcon from "@mui/icons-material/RemoveCircleOutline";
import ExpandableIcon from "@mui/icons-material/AddCircleOutline";

import Spinning from "../Spinning";
import {isEmpty} from 'lodash';

import {
    buildTreeData,
    findMember,
    getTreeData,
    calculateStep
} from '../../utils/data';

import TreeItem from "@mui/lab/TreeItem";


function Steps() {

    const [expanded, setExpanded] = useState(["3"]);
    const [selected, setSelected] = useState([]);

    const [node1, setNode1] = useState("");
    const [node2, setNode2] = useState("");
    const [nodeName1, setNodeName1] = useState("");
    const [nodeName2, setNodeName2] = useState("");

    const [step, setStep] = useState(0);

    const [val, setVal] = useState('');
    const [result, setResult] = useState({});

    useEffect(() => {
        getTreeData().then((data) => {
            buildTreeData(data).then((data) => setResult(data));
        });
    }, []);

    const handleToggle = (e, nodeId) => {
        setExpanded(nodeId);
    }

    const handleSelect = (e, nodeIds) => {
        //console.log(e.target.innerText);
        if (isEmpty(node1)) {
            setNode1(nodeIds);
            setNodeName1(e.target.innerText);
        } else {
            setNode2(nodeIds);
            setNodeName2(e.target.innerText);
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const handleChange = (e) => {
        e.preventDefault();
        const v = e.target.value.toString().replace(/\s+/g, '');
        setVal(v);
    }

    const handleFind = () => {
        if (!isEmpty(val)) {
            //console.log(val);
            findMember(val).then(data => {
                if (data.selfNodes.length > 0) {
                    setExpanded(data.upperNodes);
                    setSelected(data.selfNodes);
                } else {
                    setExpanded(["3"]);
                }
            });
        }
    }

    const handleReset = () => {
        setExpanded(["3"]);
        setVal("");
        setNode1("");
        setNode2("");
        setNodeName1("");
        setNodeName2("");
        setStep(0);
    }

    const handleCalculate = () => {
        if (!isEmpty(node1) && !isEmpty(node2) && (node1 !== node2)) {
            calculateStep(node1, node2).then((v)=>{
                setStep(v);
            });
        }
    }

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    )

    return (

        <Fragment>
            <Paper sx={{margin: "10px 10px 10px 10px", padding: "5px 5px 10px 10px"}}>
                <Typography>
                    촌수는 본인을 0촌으로, 가장 가까운 공동 선조를 기준으로 계산됩니다(친족의 범위는 민법에 의해 8촌 이내의 혈족, 4촌 이내의 인척, 배우자까지 입니다.
                </Typography>
            </Paper>
            <Paper sx={{margin: "10px 10px 10px 10px", padding: "5px 5px 10px 10px"}}>
                <Box sx={{marginBottom: "5px"}}>
                    <Typography>[회원1] {nodeName1}</Typography>
                </Box>
                <Box sx={{marginBottom: "5px"}}>
                    <Typography>[회원2] {nodeName2}</Typography>
                </Box>
                <Box sx={{marginBottom: "5px"}}>
                    <Typography>[촌수] <b>{step}</b></Typography>
                </Box>
                <Button variant="outlined" onClick={handleCalculate}>
                    calculate
                </Button>
            </Paper>
            <Paper sx={{margin: "10px 10px 10px 10px", padding: "5px 5px 10px 10px"}}>
                <TextField id="standard-required" label="Search" onChange={handleChange} placeholder="이름" value={val}/>
                    <div style={{marginTop: "10px"}}>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button onClick={handleFind}>find</Button>
                            <Button onClick={handleReset}>clear</Button>
                        </ButtonGroup>
                    </div>
            </Paper>
            <Paper sx={{margin: "10px 10px 10px 10px", padding: "5px 5px 10px 10px"}}>
                {isEmpty(result)?
                    <Spinning type="Grid" xy={50} />
                    :
                    <TreeView
                        defaultCollapseIcon={<CollapsableIcon />}
                        defaultExpandIcon={<ExpandableIcon />}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                        selected={selected}
                        expanded={expanded}
                        multiSelect={false}>
                        {renderTree(result)}
                    </TreeView>
                }
            </Paper>
        </Fragment>
    );
}

export default Steps;